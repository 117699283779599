// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.9.0_webpack@5.89.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.9.0_webpack@5.89.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
    margin: 0;
    padding: 0;
    font-family: 'Poppins', 'Inter', serif;
    -webkit-user-select: none;
            user-select: none;
}

text {
    font-family: 'Poppins', 'Inter', serif;
}

p {
    margin: 0;
}

:root {
    --swiper-theme-color: white;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAGA;IACI,SAAS;IACT,UAAU;IACV,sCAAsC;IACtC,yBAAiB;YAAjB,iBAAiB;AACrB;;AAEA;IACI,sCAAsC;AAC1C;;AAEA;IACI,SAAS;AACb;;AAEA;IACI,2BAA2B;AAC/B","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');\n@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');\n\nbody {\n    margin: 0;\n    padding: 0;\n    font-family: 'Poppins', 'Inter', serif;\n    user-select: none;\n}\n\ntext {\n    font-family: 'Poppins', 'Inter', serif;\n}\n\np {\n    margin: 0;\n}\n\n:root {\n    --swiper-theme-color: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

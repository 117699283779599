// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.9.0_webpack@5.89.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.9.0_webpack@5.89.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Form_container__QxKiQ {
    display: flex;
    flex-direction: column;
    width: 327px;
    padding-top: 48px;
    padding-bottom: 48px;
    gap: 23px;
}

.Form_formRowContainer__nFRMI {
    display: flex;
    flex-direction: row;
    gap: 12px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Form/index.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,YAAY;IACZ,iBAAiB;IACjB,oBAAoB;IACpB,SAAS;AACb;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;AACb","sourcesContent":[".container {\n    display: flex;\n    flex-direction: column;\n    width: 327px;\n    padding-top: 48px;\n    padding-bottom: 48px;\n    gap: 23px;\n}\n\n.formRowContainer {\n    display: flex;\n    flex-direction: row;\n    gap: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Form_container__QxKiQ`,
	"formRowContainer": `Form_formRowContainer__nFRMI`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.9.0_webpack@5.89.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.9.0_webpack@5.89.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SSIWelcomePage_title__kKVTU {
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
}

.SSIWelcomePage_subtitle__9qiLN {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
}

.SSIWelcomePage_description__CbZ1Q {
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    max-width: 450px;
}

.SSIWelcomePage_titleContainer__VfYhl {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 8px;
}

.SSIWelcomePage_textContainer__ifJKq {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
}

.SSIWelcomePage_rightPanel__1jeuS {
    display: flex;
    flex-grow: 1;
    align-items: center;
    flex-direction: column;
}

.SSIWelcomePage_contentContainer__mijwK {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 103px;
}

`, "",{"version":3,"sources":["webpack://./src/pages/SSIWelcomePage/index.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,QAAQ;AACZ;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,QAAQ;IACR,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,YAAY;IACZ,mBAAmB;IACnB,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,UAAU;AACd","sourcesContent":[".title {\n    font-size: 40px;\n    font-style: normal;\n    font-weight: 600;\n    line-height: 32px;\n}\n\n.subtitle {\n    font-size: 24px;\n    font-style: normal;\n    font-weight: 600;\n    line-height: 32px;\n}\n\n.description {\n    text-align: center;\n    font-size: 16px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 24px;\n    max-width: 450px;\n}\n\n.titleContainer {\n    display: flex;\n    flex-direction: column;\n    text-align: center;\n    gap: 8px;\n}\n\n.textContainer {\n    display: flex;\n    flex-direction: column;\n    gap: 8px;\n    align-items: center;\n}\n\n.rightPanel {\n    display: flex;\n    flex-grow: 1;\n    align-items: center;\n    flex-direction: column;\n}\n\n.contentContainer {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    gap: 103px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `SSIWelcomePage_title__kKVTU`,
	"subtitle": `SSIWelcomePage_subtitle__9qiLN`,
	"description": `SSIWelcomePage_description__CbZ1Q`,
	"titleContainer": `SSIWelcomePage_titleContainer__VfYhl`,
	"textContainer": `SSIWelcomePage_textContainer__ifJKq`,
	"rightPanel": `SSIWelcomePage_rightPanel__1jeuS`,
	"contentContainer": `SSIWelcomePage_contentContainer__mijwK`
};
export default ___CSS_LOADER_EXPORT___;
